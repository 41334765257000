import React,{useEffect, useState, useLayoutEffect} from 'react'
import logo from './assets/hayden_fractal_logo-01.svg'
import styled from 'styled-components'
import axios from 'axios'
import { Switch, Route, useLocation} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {selectUser, loginSuccess, logOutSuccess} from './components/slices/userSlice'
import {addAccessToken, removeAccessToken} from './components/slices/tokenSlice'
import {selectAccessToken} from './components/slices/tokenSlice'
import useInterval from 'use-interval'
import {useHistory} from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './components/Home'
import SignUp from './components/SignUp'
import LogIn from './components/LogIn'
import UserProfile from './components/UserProfile'
import ProductPage from './components/ProductPage'
import {baseUrl} from './assets/urls'
import { useAuth0 } from "@auth0/auth0-react";
import {triggerUpdate} from './components/slices/updateSlice'


const StyledApp = styled.div`
  font-family: 'Lato', sans-serif;
  height: 100%;
`

function App() {

  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0()

  const dispatch = useDispatch()

  const update = useSelector(state => state.update)

  // useInterval(() => {

  //   //Probably want to wrap all of this in an if statement that checks if there is a user so that this cant run before login
  //   const get = async () => {
  //     const response = await axios.get(`${baseUrl}/users/silent`, {withCredentials: true})
  //       .then((res) => {
  //         console.log(res.data)
  //         // dispatch(loginSuccess(res.data.user))
  //         dispatch(addAccessToken(res.data.accessToken))
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  //   get()
  //   console.log("access token has been refreshed")
  // }, 600000)

  // useEffect(() => {
  //   //might also do the same with this one as with the useInterval in that you dont really want it to run if the user doesnt have the cookie 
  //   const get = async () => {
  //     const response = await axios.get(`${baseUrl}/users/silent`, {withCredentials: true})
  //       .then((res) => {
  //         console.log(res.data)
  //         dispatch(loginSuccess(res.data.user))
  //         dispatch(addAccessToken(res.data.accessToken))
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  //   get()
  //   setInterval(() => {
  //     localStorage.setItem('util', JSON.stringify(false))
  //     dispatch(logOutSuccess())
  //     console.log('Interval triggered');
  //   }, 86400000);
  // }, [])

  

  useEffect(() => {
    if(!user) {
      console.log("ehh")
      
    } else if (user){
      const userObj = {
        email: user.email,
        name: user.name
      }
      // console.log(userObj)
      axios.post(`${baseUrl}/users/silent`, userObj)
        .then((res) => {
          console.log(res.data)
          dispatch(loginSuccess(res.data.user))
          dispatch(triggerUpdate())
          if(res.data.user.avatarName){
            localStorage.setItem('avatar', JSON.stringify(res.data.user.avatarName))
            localStorage.setItem('favs', JSON.stringify(res.data.user.favArray))
          } else {
            dispatch(triggerUpdate())
            localStorage.setItem('avatar', JSON.stringify("cae830f0-6395-48bc-9fda-28ae1b0fcfb7.png"))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [user, update.refreshUser])

  
  
  return (
    <StyledApp>
      <Navbar />
      <Switch>
        <Route path='/product/:_id'>
          <ProductPage />
        </Route>
        <Route path='/userprofile'>
          <UserProfile />
        </Route>
        <Route path='/login'>
          <LogIn />
        </Route>
        <Route path='/signup'>
          <SignUp />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </StyledApp>
  );
}


export default App;
