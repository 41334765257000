import {createSlice} from '@reduxjs/toolkit'

const initialProduct = []

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        product: initialProduct,
    },
    reducers: {
        productSuccess: (state,action) => {
            state.product = action.payload
            console.log(action.payload)
        },
    }
})

export const {productSuccess} = productSlice.actions;

export const selectProduct = state => state.product;

export default productSlice.reducer