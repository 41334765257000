import React, {useState} from 'react'
import styled from 'styled-components'
import {FaBars} from 'react-icons/fa'
import {AiOutlineClose} from 'react-icons/ai'
import {IoIosArrowDown} from 'react-icons/io'
import {Link} from 'react-router-dom'
import logo from '../assets/hayden_fractal_logo_large-01.svg'
// import logo from '../assets/3D_Fractal_Logo-01.svg'
// import logo from '../assets/homeScreenArt(1).png'
import {useDispatch,useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { selectUser, loginSuccess, logOutSuccess } from './slices/userSlice'
import {removeAccessToken} from './slices/tokenSlice'
import axios from 'axios'
import DesktopNav from './DeskopNav'
import MobileNav from './MobileNav'
import { useMediaQuery } from 'react-responsive'



const StyledNav = styled.nav`
    /* border: 2px solid black; */
    display: flex;
    height: auto;
    img {
        /* border: 2px solid black; */
        /* margin: 1.6%; */
        padding: 7px;
        width: 90px;
        /* height: auto; */
    }
`
const StyledHr = styled.hr`
    width: 98%;
`


function Navbar() {

    const Desktop = ({children}) => {
        const isDesktop = useMediaQuery({minWidth: 700})
        return isDesktop ? children : null
    }

    const Mobile = ({children}) => {
        const isMobile = useMediaQuery({maxWidth: 700})
        return isMobile ? children : null
    }


    return (
        <>
            <StyledNav>
                <Link to="/home">
                    <img src={logo} alt="logo"></img>
                </Link>
                <Desktop>
                    <DesktopNav />
                </Desktop>
                <Mobile>
                    <MobileNav />
                </Mobile>
            </StyledNav>
            <StyledHr/>
        </>
    )
}

export default Navbar