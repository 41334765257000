import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import {useSelector,useDispatch} from 'react-redux'
import {selectUser} from './slices/userSlice'
import defaultImg from '../assets/default-user-image.png'
import {baseUrl} from '../assets/urls'
import {GrLocation} from 'react-icons/gr'
import {AiOutlineTwitter} from 'react-icons/ai'
import {useForm} from 'react-hook-form'
import axios from "axios"
import {useHistory} from 'react-router-dom'
import ImgEditor from './ImgEditor'
import {AiOutlineClose} from 'react-icons/ai'
import {userUpdate} from '../components/slices/updateSlice'

const StyledInfo = styled.div`
    .wrapperDiv{
        /* border: 2px solid black; */
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .imgDiv{
            /* border: 2px solid black; */
            img{
            width: 170px;
            border-radius: 50%;
            /* margin-left: 15%; */
            }
        }
        .imgTemp {
            width: 72%;
            /* border: 2px solid black; */
            height: auto;
            margin-right: 20px;
            .preLabel{
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                font-size: .6rem;
            }
            form{
                /* border: 2px solid black; */
                
                .previewDiv{
                    /* border: 2px solid black; */
                    width: 70%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    .cancelButton{
                        /* border: 2px solid black; */
                        width: 30px;
                        height: 30px;
                        justify-content: flex-end;
                        
                        &:hover{
                            cursor: pointer;
                            color: #E54526;
                        }
                    }
                }
                #crop{
                width: 120px;
                height: 20px;
                padding: 5px;
                background: #f0f3f5;
                border: #f0f3f5;
                border-radius: 5px;
                text-align: center;
                &:hover{
                cursor: pointer;
                border: 2px solid #47CF73;
                }
                
            }
            .preLabel{
                /* border: 2px solid black; */
                font-size: 1rem;
            }
            img{
            height: 60px;
            }
            input[type="file"] {
                display: none;
            }
            .custom-file-upload {
                /* border: 1px solid #ccc;
                border-radius: 5px;
                display: inline-block;
                padding: 6px 12px;
                cursor: pointer;
                height: 20px; */
                display: inline-block;
                width: 120px;
                height: 20px;
                padding: 5px;
                background: #f0f3f5;
                border: #f0f3f5;
                border-radius: 5px;
                text-align: center;
                margin-top: 15px; 
                &:hover{
                cursor: pointer;
                border: 2px solid #47CF73;
                }
                
            }
            h1{
                margin-bottom: 5%;
            }
            
            }
        }
        .editForm {
            /* border: 2px solid black; */
            width: 80%;
            button{
                width: 90px;
                height: 25px;
                background: #f0f3f5;
                border: #f0f3f5;
                border-radius: 5px;
                &:hover{
                cursor: pointer;
                border: 2px solid #47CF73;
                }
            }
            .inputDiv {
                /* border: 2px solid black;  */
                width: 100%;
                display: flex;
                flex-direction: column;
                label{
                    font-size: 1rem;
                    margin-top: 2%;
                    margin-bottom: 1%;
                }
                input{
                    font-size: 16px;
                    height: 30px;
                    width: 90%;
                    margin-top: 2%;
                    margin-bottom: 1%;
                }
                textarea{
                    font-family: 'Lato', sans-serif;
                    font-size: 16px;
                    margin-top: 2%;
                    margin-bottom: 2%;
                    width: 90%;
                }
            }
        }
        .buttonDiv {
            /* border: 2px solid black; */
            width: 70%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            button{
                width: 90px;
                border:2px solid #f0f3f5;
                height: 25px;
                background: white;
                border-radius: 5px;
                &:hover{
                cursor: pointer;
                border: 2px solid #47CF73;
                }
            }
        }
        .editDiv{
            /* border: 2px solid black; */
            width: 75%;
            max-width: 300px;
            margin-top: 2%;
            /* margin-left: 15%; */
            display: flex;
            justify-content: flex-end;
            
        }
        .infoDiv{
            /* border: 2px solid black; */
            width: 70%;
            margin-top: 2%;
            /* margin-left: 15%; */
            .name{
                font-size: 1.5rem;
            }
            .bio{
                margin-top: 4%; 
                margin-bottom: 4%;
            }
            .location{
                margin-top: 4%; 
                display: flex;
            }
            .twitter{
                margin-top: 4%; 
                display: flex;
            }
            .icon{
                margin-right: 2%;
            }
        }
    }
`

function UserInfo() {

    const user = useSelector(state => state.user)

    const update = useSelector(state => state.update)

    const dispatch = useDispatch()

    // const img = `${baseUrl}/avatar_file/get_file/${userImg}`
    
    const [img, setImg] = useState(defaultImg)
    
    const [previewFile, setPreviewFile] = useState("")

    const [isEditing, setIsEditing] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('')

    const {register, handleSubmit, setValue} = useForm()

    const history = useHistory()

    const imgLoad = () => {
        setIsLoaded(true)
    }

    const cancelImg = () => {
        setPreviewFile("")
    }

    const editToggle = () => {
        setIsEditing(!isEditing)
        setPreviewFile("")
    }

    // const handleImg = async () => {
    //         const userAvatar = await user
    //         console.log(userAvatar)
    // }

    // console.log(localStorage.getItem('avatar'))

    useEffect(() => {
        
        function valueCheck(value) {
            if(value) {
                return value
            } else {
                return null
            }
        }
        setValue("name", valueCheck(user.user.name))
        setValue("twitterHandle", valueCheck(user.user.twitterHandle))
        setValue("company", valueCheck(user.user.company))
        setValue("location", valueCheck(user.user.location))
        setValue("bio", valueCheck(user.user.bio))
    },[isEditing, update.refreshUser])

    

    useEffect(() => {
        const userImg = JSON.parse(localStorage.getItem('avatar'))
        setImg(`${baseUrl}/avatar_file/get_file/${userImg}`)
        cancelImg()
    },[update])

    const onSubmit = (data) => {
        // const file = data.demoFile[0]
        // if(file){
        //     const formdata = new FormData()
        // formdata.append("demo_file", data.demoFile[0], `${data.demoFile[0].name}`)
        // // console.log(formdata.get("demo_file").name)
    
        // const avatarObj = {
        //     email: user.user.email,
        //     avatarName: formdata.get("demo_file").name
        // }
        // console.log(avatarObj)
        // axios.post(`${baseUrl}/avatar_file/upload`, formdata)
        //   .then(response => console.log(response))
        //   // .then(result => console.log(result))
        //   .catch(err => console.log('err', err))
    
        // axios.put(`${baseUrl}/users/avatar`, avatarObj)
        //     .then(response => console.log(response))
        //     .catch(err => console.log('err', err))
        // } else {
        //     return "no file chosen"
        // }
        console.log(data)
    }

    const onFormSubmit = (data) => {
        const editedUser = {
            email: user.user.email,
            name: data.name,
            twitterHandle: data.twitterHandle,
            company: data.company,
            location: data.location,
            bio: data.bio
        }
        console.log(editedUser)
        axios.put(`${baseUrl}/users/edit`, editedUser)
            .then((res) => {
                if(res){
                    // console.log(res)
                    dispatch(userUpdate())
                    setIsEditing(!isEditing)
                }
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleChange = (event) => {
        setPreviewFile(URL.createObjectURL(event.target.files[0]))
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    return(
        <StyledInfo>
        <div className='wrapperDiv'>
                <div className='imgDiv'>
                    {/* <img src={defaultImg} style={isLoaded ? {display: "none"} : {}}></img> */}
                    <img src={img} style={isLoaded ? {}: {display: "none"}} onLoad={imgLoad}></img>
                </div>
                <div className='buttonDiv'>
                    {isEditing ? <><button onClick={editToggle}>Cancel</button></> : <> <div className="editButton">
                    <button onClick={editToggle}>Edit profile</button>
                </div> </>}
                </div>
                {isEditing ? <>
                    <div className="imgTemp">
                        <label>User Image</label>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {previewFile ?<div ></div>  : 
                            <label className="custom-file-upload">
                                <input type="file" name="demoFile" ref={register} onChange={handleChange}/>
                                    Select Image
                            </label>}
                            <div className='previewDiv'>
                                {previewFile ? <AiOutlineClose className="cancelButton" onClick={cancelImg}/> : <div></div>}
                                <div>
                                    {previewFile ? <img src={previewFile}></img> : <div></div>}
                                    {previewFile ? <div>1 file (selected image)</div> : <div></div>}
                                </div>
                            </div>
                        </form>
                        {previewFile ? <ImgEditor file={previewFile}/> :  <div></div>}
                    </div>
                    <hr/>
                    <form className="editForm" onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="inputDiv">
                            <label>Name&nbsp;</label>
                            <input type="text" name="name" ref={register} placeholder="e.g. Tom Smith" ></input>
                        </div>
                        <div className="inputDiv">
                            <label>Twitter Handle&nbsp;</label>
                            <input type="text" name="twitterHandle" ref={register} placeholder="e.g. @twitter" ></input>
                        </div>
                        <div className="inputDiv">
                            <label>Company&nbsp;</label>
                            <input type="text" name="company" ref={register} placeholder="e.g. company.inc" ></input>
                        </div>
                        <div className="inputDiv">
                            <label>Location&nbsp;</label>
                            <input type="text" name="location" ref={register} placeholder="e.g. north pole" ></input>
                        </div>
                        <div className="inputDiv">
                            <label>Bio&nbsp;</label>
                            <textarea className="bioInput" type="text" name="bio" ref={register} placeholder="e.g. info about your library" ></textarea>
                        </div>
                        <button>Submit</button>
                    </form>
                </>: <> 
                <div className="infoDiv">
                    <div className='name'>{user.user.name}</div>
                    <div className='bio'>{user.user.bio}</div>
                    <div className='company'>{user.user.company}</div>
                    <div className='location'>
                        {user.user.location ? <GrLocation className='icon'/> : <div></div>}
                        <div>{user.user.location}</div>
                    </div>
                    <div className='twitter'>
                        {user.user.twitterHandle ? <AiOutlineTwitter className='icon'/> : <div></div>}
                        <div>{user.user.twitterHandle}</div>
                    </div>
                </div> </>}
        </div>
        </StyledInfo>
    )
}

export default UserInfo