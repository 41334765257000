import React from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import logo from '../assets/homeScreenArt(1).png'


const StyledHome = styled(motion.div)`
    position: relative;
    
    left: -120px;
    img {
        /* border: 2px solid black; */
        position: absolute;
        z-index: -1;
        width: 50vh;
    }
`

function Home() {
    return (
        <StyledHome>
            {/* <motion.img 
            src={logo}
            animate={{ rotate: 360 }}
            transition={{ duration: 80 }}
            /> */}
        </StyledHome>
    )
}

export default Home