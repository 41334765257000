import React, {useState,useEffect}  from 'react'
import styled from 'styled-components'
import {useSelector, useDispatch} from 'react-redux'
import defaultImg from '../assets/default-product.jpeg'
import {triggerUpdate} from './slices/updateSlice'
import axios from 'axios'
import {baseUrl} from '../assets/urls'
import { useParams } from 'react-router-dom'
import {AiOutlineStar} from 'react-icons/ai'
import {AiFillStar} from 'react-icons/ai'
import {userUpdate} from '../components/slices/updateSlice'

const StyledProductPage = styled.div`
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 1%;
    .imgDiv{
        /* border: 2px solid black;  */
        padding: 10px;
        img{
            width: 100%;
            border-radius: 2%;
        }
    }
    .info{
        /* border: 2px solid black;  */
        width: 90%;
        margin: 0 auto;
        
    }
    .favoriteDiv{
        /* border: 2px solid black; */
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        .favoriteButton{
            border: 2px solid black;
            padding: 3px;
            border-radius: 5px;
            width: 50px;
            display: flex;
            justify-content: space-between;
            &:hover{
                cursor: pointer;
            }
            .icon{
                width: 20px;
                height: 20px;
            }
            .activeIcon{
                color: #dbca2a;
                width: 20px;
                height: 20px;
            }
            .number{
                
                font-size: 20px;
            }
            .vert{
                border-left: 2px solid black;
            }
        }
        
    }
`


function ProductPage() {

    const dispatch = useDispatch()

    const user = useSelector(state => state.user)

    const {_id} = useParams()
    
    const [favToggle, setToggle] = useState(false)

    const [favoriteCount, setCount] = useState(0)

    // a lot of this needs to be saved locally, that really goes for a lot more of the app 

    // const initialProduct = useSelector(state => state.product)

    const [product, setProduct] = useState([])

    const array = JSON.parse(localStorage.getItem('favs'))

    useEffect(() => {
        axios.get(`${baseUrl}/products/id/${_id}`)
            .then(res => {setProduct(res.data[0])
                setCount(res.data[0].favorites)
            })
            .catch(err => console.log(err))
    },[])

    useEffect( async () => {
        const id = await _id.toString()
        if (array.includes(id)) {
            setToggle(true)
        } else if (!array.includes(id)) {
            setToggle(false)
        }
    }, [])

    // useEffect(() => {
        
    //     if(user.user === []) {
    //         console.log('nah')
    //     } else {
    //         console.log('yes')
    //     }
        
    // }, [user])

    // async function checkFav() {
    //         const array = await user.user.favArray
    //         const id = await _id.toString()
    //         const fav = await array.includes(id).then(fav => console.log(fav))
    // }
        

    const onFavSub = () => {
        setToggle(false)
        setCount(favoriteCount - 1)
        const favObj = {
            action: "sub",
            email: user.user.email,
            value: favoriteCount - 1,
            id: _id
        }
        axios.put(`${baseUrl}/products/fav`, favObj)
            .then(() => dispatch(userUpdate()))
            .catch(err => console.log(err))
        axios.put(`${baseUrl}/products/count`, favObj)
            .then()
            .catch(err => console.log(err))
    }

    const onFavAdd = () => {
        setToggle(true)
        setCount(favoriteCount + 1)
        const favObj = {
            action: "add",
            email: user.user.email,
            value: favoriteCount + 1,
            id: _id
        }
        axios.put(`${baseUrl}/products/fav`, favObj)
            .then(() => dispatch(userUpdate()))
            .catch(err => console.log(err))
        axios.put(`${baseUrl}/products/count`, favObj)
            .then()
            .catch(err => console.log(err))
    }

    return (
        <StyledProductPage>
            <div className="imgDiv">
                <img src={defaultImg}></img>
            </div>
            <div className="favoriteDiv">
                {favToggle ? 
                    <div className="favoriteButton" onClick={onFavSub}>
                        <AiFillStar className="activeIcon"/> 
                        <div className="vert"></div>
                        <div className="number">{favoriteCount}</div>
                    </div> : 
                    <div className="favoriteButton" onClick={onFavAdd}>
                        <AiOutlineStar className="icon"/>
                        <div className="vert"></div>
                        <div className="number">{favoriteCount}</div>
                    </div>}        
            </div>
            <ul className="info">
                <h1>{product.name}</h1>
                <li>{product.description}</li>
                <li>{product.parts}</li>
                <li>{product.price}</li>
            </ul>
        </StyledProductPage>
    )
}

export default ProductPage