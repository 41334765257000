import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {baseUrl} from '../assets/urls'
import axios from "axios"
import ProductCard from './ProductCard'
import {Link} from 'react-router-dom'

const StyledArea = styled.div`
    /* border: 2px solid black; */
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: 850px) {
        justify-content: center;
    }
    .link{
        text-decoration: none;
        color: black;
    }
`


function ProductArea() {

    const user = useSelector(state => state.user)

    const update = useSelector(state => state.update)

    const userId = user.user._id

    const [products, setProducts] = useState([])

    useEffect(() => {
        axios.get(`${baseUrl}/products/${userId}`)
            .then(res => setProducts(res.data.products))
            .catch(err => console.log(err))
        
    },[user,update])
    
    return(
        <StyledArea>
            {products.map(product => {
                return <Link to={`/product/${product._id}`} className="link" key={product._id}><ProductCard key={product._id} data={product}/></Link>
            })}
        </StyledArea>
    )
}


export default ProductArea