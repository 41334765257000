import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {FaSearch} from 'react-icons/fa'
import {useForm} from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux'
import {selectUser,logOutSuccess} from './slices/userSlice'
import {removeAccessToken} from './slices/tokenSlice'
import defaultImg from '../assets/default-user-image.png'
import UserNavButtons from './UserNavButtons'
import { useAuth0 } from "@auth0/auth0-react";

const StyledDesktop = styled.div`
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .searchForm {
        border: 4px solid #f0f3f5;
        /* border: 2px solid black; */
        width: 80%;
        height: 40px;
        border-radius: 5px;
        .searchDiv {
            width: 100%;
            height: 40px; 
            background: #f0f3f5;
            border: none;
            display: flex;
            align-items: center;
            /* border: 4px solid #f0f3f5; */
            .searchIcon {
                color: #757575;
            }
            input {
                width: 100%;
                height: 40px;
                border: none;
                background: #f0f3f5;
                outline: none;
                font-size: 1.3rem;
                /* border: 2px solid black; */
            }
        }
    }
    .utilButtonDiv {
        /* border: 2px solid black; */
        width: 220px;
        height: 45px;
        /* min-height: 30px; */
        .notLoggedIn {
            /* border: 2px solid black; */
            height: 45px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .signUp {
                width: 90px;
                height: 43px;
                font-size: 100%;
                border: none;
                border-radius: 5px;
                background: #47CF73;
                &:hover {
                    background: #3aa65d;
                    cursor: pointer;
                }
            }
            .logIn {
                width: 90px;
                height: 43px;
                font-size: 100%;
                border: none;
                border-radius: 5px;
                background: #f0f3f5;
                &:hover {
                    background: #d4d4d4;
                    cursor: pointer;
                }
            }
        }
    }
    
`

function DesktopNav() {

    // const user = useSelector(selectUser)

    const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0()

    // const renderDesktopButtons = () => {
    //     if(user.user.name) {
    //         return  <UserNavButtons />
    //     } else if(!user.user.name) {
    //         return <div className='notLoggedIn'>
    //                     <Link to='/signup'><button className='signUp'>Sign Up</button></Link>
    //                     <Link to='/login'><button className='logIn'>Sign In</button></Link>
    //                 </div>
    //     }
    // }

    const renderDesktopButtons = () => {
        if(isLoading || isAuthenticated) {
            return  <UserNavButtons />
        } else if(!isAuthenticated) {
            return <div className='notLoggedIn'>
                        <button className="logIn" onClick={() => loginWithRedirect()}>Log In</button>
                        <button className="signUp" onClick={() => loginWithRedirect()}>Sign Up</button>
                    </div>
        }
    }

    return (
        <StyledDesktop>
            <form className='searchForm'>
                <div className='searchDiv'>
                    <FaSearch className='searchIcon' size = '20px'/>
                    <input type='text' name='search' placeholder=' search partpass...'/>
                </div>
            </form>
            <div className='utilButtonDiv'>
                {renderDesktopButtons()}
            </div>
        </StyledDesktop>
    )
}

export default DesktopNav