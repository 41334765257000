import { useState, useEffect} from 'react';
import {baseUrl} from '../assets/urls'
import 'pintura/pintura.css';
import { getEditorDefaults, openDefaultEditor } from 'pintura';
import { PinturaEditorModal } from 'react-pintura';
import axios from 'axios'
import {useSelector,useDispatch} from 'react-redux'
import {triggerUpdate} from '../components/slices/updateSlice'
import styled from 'styled-components'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledButton = styled.button`
    width: 120px;
    height: 30px;
    padding: 5px;
    background: #f0f3f5;
    border: #f0f3f5;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
     &:hover{
        cursor: pointer;
        border: 2px solid #47CF73;
    }
`

function ImgEditor(props) {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)    
    const {file} = props
    const [result, setResult] = useState('')
    // get default properties
    // const editor = getEditorDefaults();
    const editor = getEditorDefaults()

    useEffect(() => {
        console.log(result)
    },[result])

    // const notify = () => toast("Image uploaded successfully!");

    const onSubmit = () => {
        // const file = 
        // if(file){
        //     const formdata = new FormData()
        // formdata.append("demo_file", data.demoFile[0], `${data.demoFile[0].name}`)
        // // console.log(formdata.get("demo_file").name)
    
        // const avatarObj = {
        //     email: user.user.email,
        //     avatarName: formdata.get("demo_file").name
        // }
        // console.log(avatarObj)
        // axios.post(`${baseUrl}/avatar_file/upload`, formdata)
        //   .then(response => console.log(response))
        //   // .then(result => console.log(result))
        //   .catch(err => console.log('err', err))
    
        // axios.put(`${baseUrl}/users/avatar`, avatarObj)
        //     .then(response => console.log(response))
        //     .catch(err => console.log('err', err))
        // } else {
        //     return "no file chosen"
        // }

        //THIS NEEDS TO BE CHANGED OR THERE WILL BE MAD CONFUSION FOR YOU!!!!
        const uploadObj = {
            email: user.user.email,
            avatarName: result.name,
        }

        axios.put(`${baseUrl}/users/avatar`, uploadObj)
            .then(response => console.log(response))
            .catch(err => console.log('err', err))

        const formdata = new FormData()
        formdata.append("demo_file", result, `${result.name}`)

        // console.log(formdata)
    
        axios.post(`${baseUrl}/avatar_file/upload`, formdata,{headers: {
            'content-type': 'multipart/form-data'
        }}
        )
            // .then(res => notify())
            .then(res => {
                if(res) {
                    // notify();
                    localStorage.setItem('avatar', JSON.stringify(result.name));
                    dispatch(triggerUpdate());
                }
            })
            .catch(err => console.log('err', err))

        
    }

    return (
        <div className="App">
            <PinturaEditorModal
                {...editor}
                src={file}
                imageCropAspectRatio={1}
                onProcess={(res) => setResult(res.dest)}
            ></PinturaEditorModal>
            <ToastContainer />
        <StyledButton onClick={onSubmit}>Upload</StyledButton>
        </div>
        
    );
}

export default ImgEditor;