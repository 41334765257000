import {createSlice} from '@reduxjs/toolkit'


const initialToken = 'none'

export const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        token: initialToken,
        token2: initialToken,
    },
    reducers: {
        addAccessToken: (state,action) => {
            state.token = action.payload
        },
        removeAccessToken: (state,action) => {
            state.token = null
        }
    }
})

export const {addAccessToken, removeAccessToken} = tokenSlice.actions

export const selectAccessToken = state => state.token

export default tokenSlice.reducer

