import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import {useSelector, useDispatch} from 'react-redux'
import {selectUser} from './slices/userSlice'
import defaultImg from '../assets/default-user-image.png'
import {baseUrl} from '../assets/urls'
import {GrLocation} from 'react-icons/gr'
import {AiOutlineTwitter} from 'react-icons/ai'
import {useForm} from 'react-hook-form'
import axios from "axios"
import {useHistory} from 'react-router-dom'
import {triggerUpdate} from '../components/slices/updateSlice'

const StyledUpload = styled.div`
    /* border: 2px solid black; */
    padding: 30px;
    margin-top: 20px;
    margin: 0 auto;
    height: 90%;
    width: 80%;
    form{
        button{
            width: 90px;
            height: 25px;
            background: #f0f3f5;
            border: #f0f3f5;
            border-radius: 5px;
            &:hover{
                cursor: pointer;
                border: 2px solid #47CF73;
            }
        }
    }
    h1{
        font-size: 1.3rem;
        text-align: center;
    }
    .inputDiv{
        display: flex;
        flex-direction: column;
        input{
            font-size: 16px;
            width: 50%;
            height: 30px;
            width: 100%;
            margin-top: 2%;
            margin-bottom: 1%;
        }
        textarea{
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            width: 50%;
            height: 60px;
            width: 100%;
            margin-top: 2%;
            margin-bottom: 1%;
        }
    }
`


function ProductUpload() {

    const user = useSelector(state => state.user)

    const {register, handleSubmit} = useForm()

    const dispatch = useDispatch()

    const onSubmit = (data) => {
        const {name, description, parts, price } = data
        const userId = user.user._id
        const product = {
            userId,
            name,
            description,
            parts,
            price
        }
        axios.post(`${baseUrl}/products`, product)
            .then((res) => {
                    console.log(res)
                    dispatch(triggerUpdate())
            })
            .catch(err => console.log(err))
    }

    return(
        <StyledUpload>
            <div className="imgTemp">
                <h1>Add Product</h1>
                <div>
                    {/* <h2>Upload product file</h2> */}
                    {/* <form onSubmit={handleSubmit(onSubmit)}>
                        <input className="fileInput" type="file" name="demoFile" ref={register}/>
                        <button>Upload</button>
                    </form> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <input className="fileInput" type="file" name="demoFile" ref={register}/> */}
                        <div className="inputDiv">
                            <label>Name&nbsp;</label>
                            <input type='text' name="name" ref={register}></input>
                            <label>Parts List&nbsp;</label>
                            <input type='text' name="parts" ref={register}></input>
                            <label>Price&nbsp;</label>
                            <input type='text' name="price" ref={register}></input>
                            <label>Description&nbsp;</label>
                            <textarea type='text' name="description" ref={register}></textarea>
                        </div>
                        <button>Submit</button>
                    </form> 
                </div>
            </div>
        </StyledUpload>
    )
}

export default ProductUpload