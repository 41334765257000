import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import { Auth0Provider } from "@auth0/auth0-react";


ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Auth0Provider
        domain="dev-kskb0qct.us.auth0.com"
        clientId="il97cTzUtHIIndloPPXPyHwaDedI5uTx"
        redirectUri={window.location.origin}
      >
          <App />
      </Auth0Provider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);


