import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {FaBell} from 'react-icons/fa'
import {IoIosArrowDown} from 'react-icons/io'
import defaultImg from '../assets/default-user-image.png'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {selectUser,logOutSuccess} from './slices/userSlice'
import {removeAccessToken} from './slices/tokenSlice'
import axios from 'axios'
import onClickOutside from 'react-onclickoutside'
import {baseUrl} from '../assets/urls'
import { useAuth0 } from "@auth0/auth0-react";

const StyledUserButtons = styled.div`
    .profileDropdown{
        /* border: 2px solid black; */
        display: flex;
        /* width: 200px; */
        /* justify-content: flex-end; */
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        .bell{
            margin-left: 10px;
        }
        &:hover{
            cursor: pointer;
        }
        .imgDiv{
            /* border: 2px solid black; */
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
            /* border: 2px solid black; */
            width: 26px;
            border-radius: 50%;
            }
            .dropdownIcon{
                /* border: 2px solid black; */
                color: black;
            }
        }

        .signOut{
            border: 2px solid black;
            border-radius: 5px;
            background: white;
            width: 90px;
            height: 43px;
            font-size: 90%;
            &:hover {
                cursor: pointer;
            }
        }
        
        /* button{
            border: 2px solid black;
            background: none;
            width: 80px;
            height: 33px;
            font-size: 90%;
            border-radius: 5px;
            background: white;
            border: 3px solid #f25738;
            border-radius: 4px;
            margin-right: 10px;
            width: 90px;
            height: 43px;
            font-size: 1.2rem;
            color: #f25738;
            &:hover{
                cursor: pointer;
                color: #f25738;
            }
        } */
    }
    .profileMenu{
        /* border: 2px solid black; */
        border-radius: 4px;
        width: 190px;
        padding: 8px;
        background: #f0f3f5;
        margin-top: 10px;
        margin-right: 12px;
        position: absolute;
        li{
            margin-left: 5%;
            margin-top: 8%;
            margin-bottom: 8%;
            font-size: 1rem;
            &:hover{
                cursor: pointer;
                color: #8a8a8a;
            }
        }
        .dropLink{
            text-decoration: none;
            color: black; 
            
        }
    }
`


function UserNavButtons() {

    const dispatch = useDispatch()

    const history = useHistory()

    const user = useSelector(selectUser)

    const update = useSelector(state => state.update)

    const [desktopMenuStatus, setDesktopMenuStatus] = useState(false)

    const { logout } = useAuth0();

    const desktopMenuToggle = () => {
        setDesktopMenuStatus(!desktopMenuStatus)
    }

    UserNavButtons.handleClickOutside = () => setDesktopMenuStatus(false)

    const [img, setImg] = useState(defaultImg)

    const [isLoaded, setIsLoaded] = useState(false)

    const imgLoad = () => {
        setIsLoaded(true)
    }

    useEffect(() => {
        const userImg = JSON.parse(localStorage.getItem('avatar'))
        setImg(`${baseUrl}/avatar_file/get_file/${userImg}`)
    },[update])

    
    // const  img = `${baseUrl}/avatar_file/get_file/${user.user.avatarName}`

    return (
        <StyledUserButtons>
            <div className="profileDropdown">
                <FaBell className="bell"/>
                <div className="imgDiv" onClick={desktopMenuToggle}>
                    <img src={defaultImg} style={isLoaded ? {display: "none"} : {}}></img>
                    <img src={img} style={isLoaded ? {}: {display: "none"}} onLoad={imgLoad}></img>
                    <IoIosArrowDown className='dropdownIcon'/>
                </div>
                <button className="signOut" onClick={() => logout({ returnTo: window.location.origin })}>Sign out</button>
            </div>
            <div className='profileMenu' style={desktopMenuStatus ? {} : {visibility: "hidden"}}>
                <ul>
                    <Link className="dropLink" to='/home' onClick={desktopMenuToggle}><li>Home</li></Link>
                    <Link className="dropLink" to='/userprofile' onClick={desktopMenuToggle}><li>User Profile</li></Link>
                    <Link className="dropLink" to='#' onClick={desktopMenuToggle}><li>Downloads</li></Link>
                    <Link className="dropLink" to='/#' onClick={desktopMenuToggle}><li>Explore</li></Link>
                </ul>
            </div> 
     </StyledUserButtons>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => UserNavButtons.handleClickOutside
  };

export default onClickOutside(UserNavButtons, clickOutsideConfig);