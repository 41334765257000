import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import {useHistory} from 'react-router-dom'
// import logo from '../assets/3D_Fractal_Logo-01.svg'
import logo from '../assets/hayden_fractal_logo_large-01.svg'
// import logo from '../assets/homeScreenArt(1).png'
import axios from 'axios'
import {baseUrl} from '../assets/urls'

const StyledSignUp = styled.div`
    //all this hides the nav
    position:absolute;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: white;
    .wrapper{
        /* border: 2px solid black;  */
        height: 100%;
        display: flex;
        .art{
            /* border: 8px solid black; */
            background-image: linear-gradient(to right, #f0f3f5, white);
            width: 50%;
            @media (max-width: 850px) {
                display: none;
            }
        }
        .signUp{
            /* border: 2px solid black; */
            width: 50%;
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            @media (max-width: 850px) {
                width: 100%;
            }
            .iconDiv{
                /* border: 2px solid black; */
                margin-top: 10px;
                height: 30%;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h1{
                    /* border: 2px solid black; */
                    width: 60%;
                    margin: 0 auto;
                    padding: 15px;
                    font-size: 1.9rem;
                    text-align: center; 
                }
                img{
                    /* border: 2px solid black; */
                    width: 15vh;
                    margin: 0 auto;
                }
            }
            .signUpForm{
                border: 5px solid #f0f3f5;
                border-radius: 5px;
                background: #f0f3f5;
                width: 80%;
                height: 340px;
                margin: 0 auto;
                
            }
            .inputDiv{
                display: flex;
                flex-direction: column;
                height: 19%;
                padding: 10px;
                margin-top: 10px;
                label {
                    margin: 5px;
                    font-size: 1.1rem;
                }
                input{
                    font-size: 17px;
                    border: none;
                    border-radius: 8px;
                    height: 25px;
                }
            }
            .buttonDiv{
                /* border: 2px solid black; */
                display: flex;
                
                button{
                    font-size: 100%;
                    border: none;
                    border-radius: 5px;
                    width: 90px;
                    height: 43px;
                    border: none;
                    margin: 0 auto;
                    background: #47CF73;
                    &:hover{
                        cursor: pointer;
                        background: #3aa65d;
                    }
                }
            }
        }
    }
`

function SignUp() {

    const history = useHistory()

    const {register, handleSubmit, setValue} = useForm()

    const back = () => {
        history.push('/home')
    }

    const onSubmit = (data) => {
        const newUser = {
            name: data.name,
            email: data.email,
            password: data.password,
        }
        axios.post(`${baseUrl}/users`, newUser)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
        history.push('/login')
        setValue('name', '')
        setValue('password', '')
        setValue('email', '')
    }

    return (
        <StyledSignUp>
            <div className='wrapper'>
                <div className='art'>
                    
                </div>
                <div className='signUp'>
                    <div className='iconDiv'>
                        <img src={logo}></img>
                        <h1>Makfst</h1>
                    </div>
                    <form className='signUpForm' onSubmit={handleSubmit(onSubmit)}>
                        <div className='inputDiv'>
                            <label>Full Name</label>
                            <input type="text" name="name" ref={register}/>
                        </div>
                        <div className='inputDiv'>
                            <label>Email</label>
                            <input type="text" name="email" ref={register}/>
                        </div>
                        <div className='inputDiv'>
                            <label>Password</label>
                            <input type="password" name="password" ref={register}/>
                        </div>
                        <div className='buttonDiv'>
                            <button>Sign Up</button>
                        </div>
                    </form>
                </div>
            </div>
        </StyledSignUp>
    )
}

export default SignUp