import {createSlice} from '@reduxjs/toolkit'


const initialState = false

export const updateSlice = createSlice({
    name: 'update',
    initialState: {
        update: initialState,
        refreshUser: initialState
    },
    reducers: {
        triggerUpdate: (state,action) => {
            state.update = !state.update
        },
        userUpdate: (state,action) => {
            state.refreshUser = !state.refreshUser
        },
    }
})

export const {triggerUpdate, userUpdate} = updateSlice.actions

export default updateSlice.reducer