import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import defaultImg from '../assets/default-product.jpeg'
import {useSelector, useDispatch} from 'react-redux'
import {productSuccess} from '../components/slices/productSlice'
import {Link} from 'react-router-dom'

const StyledCard = styled.div`
    border: 2px solid #f0f3f5;
    border-radius: 6px;
    width: 180px;
    height: 190px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover{
        cursor: pointer;
        color: #8a8a8a;
    }
    @media (max-width: 850px) {
        width: 250px;
        height: 230px;
    }
    img{
        /* border: 2px solid black; */
        border-radius: 20px;
        width: 60%;
        padding: 12px;
        
    }
    h3{
        /* border: 2px solid black; */
        padding: 7px;
    }
    h4{
        text-align: center;
    }
`

function ProductCard(props) {
    const {data} = props

    const dispatch = useDispatch()

    const openProduct = () => {
        // dispatch(productSuccess(data))
        localStorage.setItem('product_id', JSON.stringify(data._id));
    }

    return(
        <StyledCard onClick={openProduct}>
            <img src={defaultImg}></img>
            <div>
                <h3>{data.name}</h3>
                <h4>{data.price}</h4>
            </div>
        </StyledCard>
    )
}

export default ProductCard