import {configureStore} from '@reduxjs/toolkit'
import userReducer from '../components/slices/userSlice'
import tokenReducer from '../components/slices/tokenSlice'
import updateReducer from '../components/slices/updateSlice'
import productReducer from '../components/slices/productSlice'

export default configureStore({
    reducer: {
        user: userReducer,
        token: tokenReducer,
        update: updateReducer,
        product: productReducer,
    }
})

