import React, {useState} from 'react'
import styled from 'styled-components'
import {FaSearch} from 'react-icons/fa'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import {AiOutlineClose} from 'react-icons/ai'
import {useSelector, useDispatch} from 'react-redux'
import {selectUser,logOutSuccess} from './slices/userSlice'
import {useHistory} from 'react-router-dom'
import {removeAccessToken} from './slices/tokenSlice'
import axios from 'axios'
import {baseUrl} from '../assets/urls'
import { useAuth0 } from "@auth0/auth0-react";

const StyledMobile = styled.div`
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .searchForm {
        border: 4px solid #f0f3f5;
        width: 70%;
        border-radius: 5px;
        .searchDiv {
            width: 100%;
            height: 3.5vh; 
            background: #f0f3f5;
            border: none;
            display: flex;
            align-items: center;
            /* border: 4px solid #f0f3f5; */
            .searchIcon {
                color: #757575;
            }
            input {
                width: 100%;
                height: 3.3vh;
                border: none;
                background: #f0f3f5;
                outline: none;
                font-size: 1.3rem;
                /* border: 2px solid black; */
            }
        }
    }
    .menu-bars {
        font-size: 2rem;
        background: none;
        color: white;
        margin-right: 1.3rem;
        /* border: 2px solid black; */
    }
    .menu-bars.active {
        font-size: 2rem;
        background: none;
        color: #263144;
        transition-duration: .9s;
    }
    .menu-X {
        margin-left: 1rem;
        font-size: 2rem;
        background: none;
        color: #263144;
    }
    .navItem {
        margin-left: 5%;
        color: #263144;
    }
    .nav-menu {
        /* background-color: #494F5A; */
        background-color: #f0f3f5;
        opacity: 1;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 100%;
        transition: 850ms;
    }
    .nav-menu.active {
        left: 0;
        transition: 350ms;
    }
    .nav-text {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 8px 0px 8px 16px;
        list-style: none;
        height: 60px;
    }
    .nav-text a {
        text-decoration: none;
        color: black;
        font-size: 18px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        margin-left: 6px;
    }
    .nav-text a:hover {
        background-color: #ffe342;
        transition-duration: .9s;
    }
    .nav-menu-items {
        /* border: 2px solid black; */
        width: 86%;
        display: flex;
        flex-direction: column;
    }
    .navbar-toggle {
        /* background-color: #494F5A; */
        /* border: 2px solid black; */
        background-color: #f0f3f5;
        width: 100%;
        height: 80px;
        display: flex;
        opacity: .98;
        justify-content: flex-end;
        align-items: center;
    }
    .textLink{
        height: 30px;
        font-size: 1.3rem;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
        text-decoration: none;
        display: flex;
        border-bottom: 2px solid lightgrey;
    }
    .loggedIn{
        padding: 10px;
        .signOut{
            border: 2px solid black;
            border-radius: 5px;
            background: #f0f3f5;
            width: 90px;
            height: 43px;
            font-size: 90%;
            &:hover {
                cursor: pointer;
            }
        }
        /* button{
            margin: 10px;
            border: 2px solid black;
            background: #f0f3f5;
            width: 80px;
            height: 33px;
            font-size: 90%;
            border-radius: 5px;
            &:hover{
                cursor: pointer;
                color: #f25738;
                border: 3px solid #f25738;
            }
        } */
    }
    .notLoggedIn {
            /* border: 2px solid black; */
            margin: 10px;
            height: 45px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .signUp {
                margin-right: 10px;
                width: 90px;
                height: 43px;
                font-size: 100%;
                border: none;
                border-radius: 5px;
                background: #47CF73;
                &:hover {
                    background: #3aa65d;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .logIn {
                background: #d1cfcf;
                width: 90px;
                height: 43px;
                font-size: 100%;
                border: none;
                border-radius: 5px;
                &:hover {
                    background: #d4d4d4;
                }
                &:hover{
                    cursor: pointer;
                }
            }
    }
`


function MobileNav() {

    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)

    // const user = useSelector(selectUser)

    

    const dispatch = useDispatch()

    const history = useHistory()

    const onLogOut = async () => {
        const response = await axios.get(`${baseUrl}/users/logout`, {withCredentials: true})
        .then((res) => {
          console.log(res.data)
          dispatch(logOutSuccess())
          dispatch(removeAccessToken())
          history.push('/home')
        })
        .catch((err) => {
          console.log(err)
        })
        
    }

    const fullLogout = () => {
        showSidebar()
        onLogOut()
    }

    const { loginWithRedirect, user, isAuthenticated, isLoading, logout} = useAuth0()

    // const renderMobileButtons = () => {
    //     if(user) {
    //         return  <div className='loggedIn'><button onClick={fullLogout}>Sign out</button></div>
    //     } else if(!user) {
    //         return <div className='notLoggedIn'>
    //         <Link to='/signup' onClick={showSidebar}><button className='signUp'>Sign Up</button></Link>
    //         <Link to='/login' onClick={showSidebar}><button className='logIn'>Log In</button></Link>
    //     </div>
    //     }
    // }

    const renderMobileButtons = () => {
        if(user) {
            return  <div className='loggedIn'><button className="signOut" onClick={() => logout({ returnTo: window.location.origin })}>Sign out</button></div>
        } else if(!user) {
            return <div className='notLoggedIn'>
                    <button className="logIn" onClick={() => loginWithRedirect()}>Log In</button>
                    <button className="signUp" onClick={() => loginWithRedirect()}>Sign Up</button>
                </div>
        }
    }
    return (
        <StyledMobile>
            <form className='searchForm'>
                <div className='searchDiv'>
                    <FaSearch className='searchIcon' size = '20px'/>
                    <input type='text' name='search' placeholder=' search...'/>
                </div>
            </form>
            <div className='navbar'>
                <div className="hamDiv">
                    <Link to='#' className={sidebar ? "menu-bars" : "menu-bars active"}>
                        <FaBars onClick={showSidebar}/>
                    </Link>
                </div>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items' >
                    <li className='navbar-toggle'>
                        <Link to='#' className='menu-X'onClick={showSidebar}>
                            <AiOutlineClose />
                        </Link>
                    </li>
                    <Link  className="textLink" to="/" onClick={showSidebar}><li className="navItem">Home</li></Link>
                    <Link  className="textLink" to="/explore" onClick={showSidebar}><li className="navItem">Explore</li></Link>
                    <Link  className="textLink" to="/favorites" onClick={showSidebar}><li className="navItem">Favorites</li></Link>
                    <Link  className="textLink" to="/userprofile" onClick={showSidebar}><li className="navItem">User Profile</li></Link>
                    {renderMobileButtons()}
                </ul>
            </nav>
        </StyledMobile>
    )
}

export default MobileNav