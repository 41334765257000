import {createSlice} from '@reduxjs/toolkit'

const initialUser = []

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: initialUser,
    },
    reducers: {
        loginSuccess: (state,action) => {
            state.user = action.payload
            // localStorage.setItem('util', JSON.stringify(true))
            // console.log(action.payload)
        },
        logOutSuccess: (state,action) => {
            state.user = {}
            // localStorage.setItem('util', JSON.stringify(false))
            console.log(state.user)
        },
    }
})

export const {loginSuccess, logOutSuccess} = userSlice.actions;

export const selectUser = state => state.user;

export default userSlice.reducer