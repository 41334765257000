import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {selectUser} from './slices/userSlice'
import defaultImg from '../assets/default-user-image.png'
import {baseUrl} from '../assets/urls'
import {GrLocation} from 'react-icons/gr'
import {AiOutlineTwitter} from 'react-icons/ai'
import UserInfo from './UserInfo'
import ProductArea from './ProductArea'
import ProductUpload from './ProductUpload'
import axios from 'axios'
import {FaSearch} from 'react-icons/fa'
import {BsFillBackspaceFill} from 'react-icons/bs'

const StyledUserPage = styled.div`
    display: flex;
    height: 100vh;
    
    @media (max-width: 850px) {
                flex-direction: column;
            }
`

const StyledProfile = styled.div`
    /* border: 2px solid black; */
    width: 30%; 
    /* height: 100vh; */
    margin-top: 2%;
    margin-left: 2%;
    @media (max-width: 850px) {
                width: 100%;
                margin-left: 0;
            }
`
const StyledProducts = styled.div`
    /* border: 2px solid black; */
    width: 65%;
    height: 92%;
    margin-top: 2%;
    @media (max-width: 850px) {
                width: 100%;
            }
    .toggleDiv{
        /* border: 2px solid black;  */
        display: flex;
        justify-content: flex-end;
        .back{
            /* border: 2px solid black; */
            width: auto;
            height: 30px;
            margin-right: 60px;
            &:hover{
            cursor: pointer;
            color: #E54526;
            }
            @media (max-width: 850px) {
                margin-right: 30px;
            }
        }
        button{
            background: none;
            border: 2px solid black;
            border-radius: 4px;
            border:2px solid #f0f3f5;
            height: 25px;
            margin-right: 60px;
            &:hover{
            cursor: pointer;
            border: 2px solid #47CF73;
            }
            @media (max-width: 850px) {
                margin-right: 30px;
            }
        }
    }
`

function UserProfile() {

    const user = useSelector(state => state.user)

    const [isUploading, setIsUploading] = useState(false)

    const update = useSelector(state => state.update)

    const uploadToggle = () => {
        setIsUploading(!isUploading)
    }

    useEffect(() => {
        setIsUploading(false)
    },[update])

    // const userId = user.user._id

    // const [products, setProducts] = useState([])

    // useEffect(() => {
    //     axios.get(`${baseUrl}/products/${userId}`)
    //         .then(res => setProducts(res.data.products))
    //         .catch(err => console.log(err))
        
    // },[user])


    return (
        <StyledUserPage>
            <StyledProfile>
                <UserInfo />
            </StyledProfile>
            <StyledProducts>
                <div className='toggleDiv'>
                    {isUploading ? <BsFillBackspaceFill className="back" onClick={uploadToggle}>back</BsFillBackspaceFill> : <button onClick={uploadToggle}> + Add Product </button> }
                </div>
                {isUploading ? <ProductUpload /> : <ProductArea />}
            </StyledProducts>
        </StyledUserPage>
    )
}

export default UserProfile